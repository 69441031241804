import React, { FC, ReactNode } from "react";
import Slider from "react-slick";

export interface Props {
    title: string;
    children: ReactNode;
}

const EntryBenefits: FC<Props> = ({ title, children }: Props) => {
    const carouselSettings = {
        slidesToShow: 1,
        infinite: true,
        slidesToScroll: 1,
        autoplay: true,
        speed: 500,
        arrows: false,
        dots: true,
        fade: true,
        cssEase: "linear",
    };

    return (
        <div className="flex justify-center bg-gradient-to-r from-rc-pink to-rc-blue py-14 lg:h-screen lg:py-0 uwd:min-h-screen/2">
            <div className="self-center uwd:pt-10">
                <div className="text-center ">
                    <span className="font-headline text-5xl font-black text-rc-dark lg:text-6xl">{title}</span>
                </div>
                <div className="mx-auto w-screen py-10 lg:container lg:py-20 uwd:w-2/3 ">
                    <Slider {...carouselSettings}>{children}</Slider>
                </div>
            </div>
        </div>
    );
};
export default EntryBenefits;
