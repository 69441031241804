import Link from "next/link";
import { useTranslation } from "next-i18next";
import React, { FC } from "react";

export type BorderColor = "pink" | "blue";
export interface Props {
    title: string;
    text: string;
    imgUrl: string;
}

const EntryBenefitsCard: FC<Props> = ({ title, text, imgUrl }: Props) => {
    const { t } = useTranslation();
    return (
        <div className="relative">
            <div className="relative mx-auto mt-40 mb-8  w-4/5 rounded-lg lg:my-8 lg:w-3/5 ">
                <div className="absolute -inset-2 rounded-lg bg-rc-dark opacity-50 blur" />
                <div className="absolute -inset-1 rounded-lg bg-gradient-to-r from-rc-pink to-rc-blue" />
                <div className=" relative grid rounded-lg bg-rc-dark px-10 py-8   text-white lg:h-96 lg:grid-cols-4">
                    <div className="absolute left-1/2 -top-40  h-60 w-60 -translate-x-1/2 lg:top-1/2 lg:-left-12 lg:-translate-y-1/2 lg:-translate-x-0">
                        <div className="relative">
                            <div className=" absolute inset-0 h-full w-full rounded-3xl bg-gradient-to-r from-rc-pink to-rc-blue opacity-30 " />
                            <img src={imgUrl} alt={title} className="h-60 w-full rounded-3xl object-cover " />
                        </div>
                    </div>
                    <div />
                    <div className="col-span-3 pt-16 text-center lg:pt-12 lg:pr-10 lg:text-left">
                        <span className="font-headline border-b border-rc-pink pb-2 text-2xl font-bold lg:text-5xl">{title}</span>
                        <p className="truncate-2line mt-10 mb-6 lg:mb-2" dangerouslySetInnerHTML={{ __html: text }} />
                        <div className="flex justify-center lg:justify-end lg:pt-14 ">
                            <Link href="/benefits">
                                <a className="border-b border-rc-pink uppercase text-rc-gray-white ">{t("generic.read_more")}</a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default EntryBenefitsCard;
