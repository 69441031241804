import Button from "@components/buttons/button";
import SignupLoginButtons from "@components/buttons/signup-login-buttons";
import InputField from "@components/input-field";
import ServiceSignInOptions from "@components/service-sign-in-options";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import React, { FC } from "react";

export interface Props {
    bgImgUrl: string;
    text: string;
    highlightText: string;
    highlightSubtext: string;
    emailInput?: {
        value: string;
        onChange: React.ChangeEventHandler<HTMLInputElement>;
    };
    handleOpen: () => void;
}

const EntryHero: FC<Props> = ({ emailInput, bgImgUrl, text, highlightText, highlightSubtext, handleOpen }: Props) => {
    const { t } = useTranslation();

    return (
        <div
            className="relative w-full bg-rc-dark py-6 md:h-screen lg:py-0"
            style={{
                backgroundImage: `url(${bgImgUrl})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "left",
            }}
        >
            <div className="grid h-full xl:grid-cols-5 ">
                <div className="col-span-3 text-white ">
                    <div className="mx-auto flex h-full flex-col justify-evenly  gap-4 px-6 md:px-10 lg:w-2/3 lg:gap-0 lg:px-0">
                        <SignupLoginButtons
                            signUpLabel={t("generic.signup")}
                            loginLabel={t("generic.login")}
                            signUpLink="/setup"
                            loginLink="/login"
                        />
                        <Link href="/">
                            <a>
                                <img src="/logo_white.png" alt="Realclixx Logo" className="w-1/2 object-contain uwd:w-480" />
                            </a>
                        </Link>
                        <p className="text-base  text-rc-gray-white 2xl:pr-40 uwd:pr-80">{text}</p>

                        <div>
                            <p className="font-oswald text-6xl font-bold uppercase" dangerouslySetInnerHTML={{ __html: highlightText }} />
                            <p className="pt-6 font-oswald text-3xl uppercase text-rc-gray-white">{highlightSubtext}</p>
                        </div>

                        <div className="flex w-5/6 flex-col justify-start uwd:w-3/6">
                            {emailInput && (
                                <form className="relative space-y-6 md:mt-5">
                                    <InputField
                                        name="email"
                                        label={t("generic.email")}
                                        type="text"
                                        onChange={emailInput.onChange}
                                        value={emailInput.value}
                                    />
                                    <Button title={t("generic.signup")} className="mt-5 w-full" onClick={handleOpen} />
                                </form>
                            )}
                            <ServiceSignInOptions />
                            <Link href="/login">
                                <a className="pt-4">{t("generic.already_got_account")}</a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EntryHero;
