import { IAnimatedGridTile } from "@components/animated-grid/animated-grid";

export const landingPageAnimatedGrid: IAnimatedGridTile[] = [
    {
        imgUrl: "/media-icons/anim-grid/whatsapp.png",
        href: "https://whatsapp.com",
    },
    {
        imgUrl: "/media-icons/anim-grid/TwitchGlitchPurple.png",
        href: "https://www.twitch.tv/",
    },
    { imgUrl: "/media-icons/anim-grid/twitter.png", href: "https://twitter.com" },
    {
        imgUrl: "/media-icons/anim-grid/facebook.png",
        href: "https://facebook.com",
    },
    {
        imgUrl: "/media-icons/anim-grid/applemusic.png",
        href: "https://www.apple.com/de/apple-music/",
    },
    { imgUrl: "/media-icons/anim-grid/netflix.png", href: "https://netflix.com" },
    {
        imgUrl: "/media-icons/anim-grid/2000px-SoundCloud_-_Logo.svg.png",
        href: "https://soundcloud.com/",
    },
    {
        imgUrl: "/media-icons/anim-grid/2560px-EBay_logo.svg.png",
        href: "https://www.ebay.de/",
    },
    {
        imgUrl: "/media-icons/anim-grid/telegram.png",
        href: "https://telegram.org/",
    },
    { imgUrl: "/media-icons/anim-grid/youtube.png", href: "https://youtube.com" },
    {
        imgUrl: "/media-icons/anim-grid/linkedin.png",
        href: "https://de.linkedin.com/",
    },
    { imgUrl: "/media-icons/anim-grid/spotify.png", href: "https://spotify.com" },
    {
        imgUrl: "/media-icons/anim-grid/appletv.png",
        href: "https://www.apple.com/de/tv-home/",
    },
    {
        imgUrl: "/media-icons/anim-grid/Amazon_icon.svg.png",
        href: "https://www.amazon.de/",
    },
    {
        imgUrl: "/media-icons/anim-grid/messenger.png",
        href: "https://facebook.com",
    },
    {
        imgUrl: "/media-icons/anim-grid/vimeo.png",
        href: "https://vimeo.com/",
    },
    {
        imgUrl: "/media-icons/anim-grid/instagram.png",
        href: "https://instagram.com",
    },

    {
        imgUrl: "/media-icons/anim-grid/deezer.png",
        href: "https://www.deezer.com/",
    },
    {
        imgUrl: "/media-icons/anim-grid/disney-plus-logo.png",
        href: "https://www.disneyplus.com/",
    },
    {
        imgUrl: "/media-icons/anim-grid/pinterest.png",
        href: "https://pinterest.com",
    },
    {
        imgUrl: "/media-icons/anim-grid/snapchat.png",
        href: "https://www.snapchat.com/",
    },
    {
        imgUrl: "/media-icons/anim-grid/t_logo.png",
        href: "https://www.ticketmaster.de/",
    },
    {
        imgUrl: "/media-icons/anim-grid/google.png",
        href: "https://www.google.com/",
    },
    {
        imgUrl: "/media-icons/anim-grid/the-fork.png",
        href: "https://www.thefork.de/",
    },
    {
        imgUrl: "/media-icons/anim-grid/wikipedia.png",
        href: "https://www.wikipedia.com/",
    },
];
