import Button from "@components/buttons/button";
import InputField from "@components/input-field";
import ServiceSignInOptions from "@components/service-sign-in-options";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import React, { FC } from "react";

export interface Props {
    title: string;
    bgImgUrl?: string;
    emailInput?: {
        value: string;
        onChange: React.ChangeEventHandler<HTMLInputElement>;
    };
    handleOpen: () => void;
}

// TODO: Add frontend validation. And maybe already make an API-Call here later on? Or just forward to sign up process.
const EntrySignup: FC<Props> = ({ title, emailInput, bgImgUrl, handleOpen }: Props) => {
    const { t } = useTranslation();
    return (
        <div
            className="flex h-screen justify-center bg-rc-dark text-white uwd:h-screen/2 "
            style={
                bgImgUrl
                    ? {
                          backgroundImage: `url(${bgImgUrl})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                      }
                    : undefined
            }
        >
            <div className="my-auto w-full px-4 lg:w-1/2 lg:px-0">
                <div className="mx-auto flex flex-col justify-center">
                    <span className=" pb-14 text-center text-3xl font-bold lg:text-6xl">{title}</span>
                    {emailInput && (
                        <form className="relative mt-5 w-full space-y-6">
                            <InputField name="email" label={t("generic.email")} type="text" onChange={emailInput.onChange} value={emailInput.value} />
                            <Button title={t("generic.signup")} className="mt-5 w-full" onClick={handleOpen} />
                            {/* <SubmitButton title="Submit" /> */}
                        </form>
                    )}
                    <ServiceSignInOptions />
                    <Link href="/login">
                        <a className="pt-8">{t("generic.already_got_account")}</a>
                    </Link>
                </div>
            </div>
        </div>
    );
};
export default EntrySignup;
