import useBreakpoints from "@helper/custom-hooks/use-breakpoints";
import React, { FC, ReactNode } from "react";
import { triangleDownIcon } from "styles/icons";

export type BorderColor = "pink" | "blue";
export interface Props {
    title: string;
    borderColor: BorderColor;
    bgTailwind?: string;
    bgImgUrl?: string;
    children: ReactNode;
}

const EntryBox: FC<Props> = ({ title, borderColor, children, bgTailwind, bgImgUrl }: Props) => {
    const breakpoints = useBreakpoints();

    return (
        <div
            className={`${bgTailwind || "bg-rc-dark"}  uwd:min-h-screen/2 `}
            style={
                bgImgUrl
                    ? {
                          backgroundImage: `url(${bgImgUrl})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                      }
                    : undefined
            }
        >
            <div className="mx-auto lg:container">
                <div className="grid grid-cols-4 lg:grid-cols-3 ">
                    {breakpoints.isLgAndAbove && (
                        <div className="flex w-full justify-end py-16 ">
                            <span className="text-gradient font-headline pr-8 text-3xl font-black lg:w-1/2 xl:text-5xl">{title}</span>
                        </div>
                    )}
                    <div
                        className={`relative col-span-3 ml-8 flex w-full flex-col border-l py-16 md:ml-20 lg:col-span-2 xl:ml-0 ${
                            borderColor === "blue" ? "border-rc-blue" : "border-rc-pink"
                        }`}
                    >
                        <div className=" absolute -left-3 bottom-20 animate-bounce">
                            {triangleDownIcon(borderColor === "blue" ? "#3494e6" : "#ec6ead")}
                        </div>
                        <div
                            className={`${bgTailwind || "bg-rc-dark"} absolute -left-2 top-20 h-4 w-4 rounded-full border ${
                                borderColor === "blue" ? "border-rc-blue" : "border-rc-pink"
                            }`}
                        />
                        {!breakpoints.isLgAndAbove && (
                            <div className={`ml-10 flex w-full border-b pb-2 ${borderColor === "blue" ? "border-rc-blue" : "border-rc-pink"}`}>
                                <span className="text-gradient font-headline text-5xl font-black">{title}</span>
                            </div>
                        )}
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default EntryBox;
