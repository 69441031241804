import Link from "next/link";
import React, { FC } from "react";

export interface IAnimatedGridTile {
    size: number;
    ratio: number;
    gridSize: number;
    offset: { x: number; y: number };
    centered?: boolean;
    networkName: string;
    imgUrl: string;
    onClick?: () => void;
    href?: string;
}

const AnimatedGridTile: FC<IAnimatedGridTile> = ({ size, ratio, offset, centered, networkName, imgUrl, onClick, href }: IAnimatedGridTile) => {
    if (href) {
        return (
            <Link href={href}>
                <a
                    type="button"
                    className={`absolute grid place-content-center rounded-lg bg-rc-dark shadow transition duration-700 hover:scale-105${
                        centered ? "opacity-0" : "opacity-100"
                    }`}
                    style={{
                        width: `${size * ratio}%`,
                        height: `${size}%`,
                        transform: centered
                            ? "translate(0, 0) scale(0.5)"
                            : `translate(${offset.x * (50 / (size * ratio))}%, ${offset.y * (50 / size)}%)`,
                        transformStyle: "preserve-3d",
                        left: `${50 - size / 2}%`,
                        top: `${50 - size / 2}%`,
                    }}
                >
                    <img src={imgUrl} alt={networkName} />
                </a>
            </Link>
        );
    }

    return (
        <button
            type="button"
            onClick={onClick}
            className={`absolute grid place-content-center rounded-lg bg-rc-dark shadow transition duration-700 hover:scale-105${
                centered ? "opacity-0" : "opacity-100"
            }`}
            style={{
                width: `${size * ratio}%`,
                height: `${size}%`,

                //   transform: centered
                //   ? "translate(0, 0)"
                //   : `translate(${offset.x * (50 / (size * ratio))}%, ${
                // 		  offset.y * (50 / size)
                // 	}%) perspective(${values.clientWidth}px) rotateX(${
                // 		  values.rotateY
                // 	}deg) rotateY(${values.rotateX}deg) scale3d(1, 1, 1)`,

                transform: centered ? "translate(0, 0) scale(0.5)" : `translate(${offset.x * (50 / (size * ratio))}%, ${offset.y * (50 / size)}%)`,
                // transform: centered
                // 	? "translate(0, 0)"
                // 	: `translate(${offset.x * (50 / (size * ratio))}%, ${
                // 			offset.y * (50 / size)
                // 	  }%) rotateX(${calculateRotate(
                // 			size * ratio,
                // 			values[0]
                // 	  )}deg) rotateY(${calculateRotate(
                // 			size,
                // 			values[1]
                // 	  )}deg) scale3d(1, 1, 1)`,
                transformStyle: "preserve-3d",
                left: `${50 - size / 2}%`,
                top: `${50 - size / 2}%`,
            }}
        >
            <img src={imgUrl} alt={networkName} />
        </button>
    );
};

export default AnimatedGridTile;
