import Button from "@components/buttons/button";
import InputField from "@components/input-field";
import ServiceSignInOptions from "@components/service-sign-in-options";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import React, { FC } from "react";

export interface Props {
    bgImgUrl: string;
    text: string;
    highlightText: string;
    highlightSubtext: string;
    emailInput?: {
        value: string;
        onChange: React.ChangeEventHandler<HTMLInputElement>;
    };
}

const EntryHeroMobile: FC<Props> = ({ emailInput, text, bgImgUrl, highlightText, highlightSubtext }: Props) => {
    const { t } = useTranslation();

    return (
        <>
            <div
                className="relative w-full bg-black py-6 md:h-screen/2 lg:py-0"
                style={{
                    backgroundImage: `url(${bgImgUrl})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "right",
                }}
            >
                <div className="grid h-full xl:grid-cols-5 ">
                    <div className="col-span-3 pt-10 text-white">
                        <div className="mx-auto flex h-full flex-col justify-evenly  gap-4 px-6 md:px-10 lg:w-2/3 lg:gap-0 lg:px-0">
                            <div className="text-right">
                                <p
                                    className="pt-60 font-oswald text-4xl font-bold uppercase lg:text-7xl "
                                    dangerouslySetInnerHTML={{ __html: highlightText }}
                                />
                                <div className="flex flex-row justify-between">
                                    <div />
                                    <div className="h-2 w-2/3 justify-self-end border-b border-rc-pink" />
                                </div>
                                <p className="pt-6 text-center font-oswald text-2xl uppercase text-rc-gray-white md:text-right lg:text-4xl">
                                    {highlightSubtext}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-start bg-black px-4 pb-6 text-center text-white md:px-10 md:pt-6">
                <p className="pb-10 text-base text-rc-gray-white">{text}</p>
                {emailInput && (
                    <form className="relative space-y-6 md:mt-5">
                        <InputField name="email" label={t("generic.email")} type="text" onChange={emailInput.onChange} value={emailInput.value} />
                        <Link href="/setup">
                            <a>
                                <Button title={t("generic.signup")} className="mt-5 w-full" />
                            </a>
                        </Link>
                    </form>
                )}
                <ServiceSignInOptions />
                <Link href="/login">
                    <a className="mx-auto pt-4 text-xl">{t("generic.already_got_account")}</a>
                </Link>
            </div>
        </>
    );
};

export default EntryHeroMobile;
