import AuthStore from "@helper/auth/auth-store";
import { useRouter } from "next/router";
import { useEffect } from "react";

const useUnAuthOnly = (skipRedirect = false) => {
    const router = useRouter();
    const user = AuthStore((state) => state.user);
    const isAdmin = user?.role === "admin";

    useEffect(() => {
        if (user && !skipRedirect) {
            if (isAdmin) {
                router.push("/admin-dashboard/users");
            } else {
                router.push("/multimedia/videos");
            }
        }
    }, [user]);
};

export default useUnAuthOnly;
