import useBreakpoints from "@helper/custom-hooks/use-breakpoints";
import useHover from "@helper/custom-hooks/use-hover";
import useIndexState from "@helper/custom-hooks/use-index-state";
import { shuffleArray } from "@helper/shuffle-array";
import React, { FC, useEffect, useState } from "react";

import AnimatedGridTile from "./subcomponents/animated-grid-tile";

// Grids can be added and modified here. They could be generated randomly later on.
// Numbers are: [ size in %, horizontal offset from the center in %, vertical offset from the center in % ]
const GRIDS_LARGE: [number, number, number][][] = [
    [
        [30, -65, -50],
        [20, -10, -50],
        [30, 45, -62],
        [15, -75, 10],
        [30, -25, 25],
        [20, 18, 0],
        [28, 30, 60],
        [28, 80, 25],
    ],
    [
        [20, -55, -50],
        [30, -5, -50],
        [15, 35, -32],
        [30, -65, 10],
        [15, -25, 65],
        [20, -20, 15],
        [30, 30, 40],
        [25, 80, 0],
    ],
    [
        [20, -45, -45],
        [30, 15, -50],
        [15, 65, -50],
        [30, -65, 30],
        [20, -10, 70],
        [25, -15, 15],
        [20, 30, 25],
        [30, 80, 40],
    ],
];

const GRIDS_SMALL: [number, number, number][][] = [
    [
        [15, -50, -65],
        [20, 50, -70],
        [12, -50, -10],
        [15, 35, -25],
        [18, -70, 45],
        [12, 10, 15],
        [18, 80, 25],
        [20, 25, 70],
    ],
    [
        [20, -60, -65],
        [15, 70, -75],
        [15, -50, -10],
        [20, 35, -25],
        [15, -70, 45],
        [12, -5, 25],
        [20, 70, 25],
        [20, 25, 70],
    ],
];

export interface IAnimatedGridTile {
    imgUrl: string;
    href: string;
}

interface IAnimatedGrid {
    tiles: IAnimatedGridTile[];
}

const AnimatedGrid: FC<IAnimatedGrid> = ({ tiles }: IAnimatedGrid) => {
    const breakpoints = useBreakpoints();
    const [shuffledTiles, setShuffledTiles] = useState<IAnimatedGridTile[]>(tiles);
    const [isMounted, setIsMounted] = useState<boolean>(true);

    const TileSize = (): number => {
        if (breakpoints.isSm) {
            return 30;
        }
        if (breakpoints.isMd) {
            return 30;
        }

        if (breakpoints.isLg) {
            return 40;
        }

        if (breakpoints.isXl) {
            return 50;
        }

        if (breakpoints.is2xl) {
            return 50;
        }

        return 20;
    };

    // Ratio sets the height in relation to the width. 1 would bei exactly the same height and width. 0.8 means 80% of the width and so forth.
    const CONFIG = {
        gridSizeInRem: 50,
        ratio: breakpoints.isLgAndAbove ? 0.8 : breakpoints.isMd ? 1.3 : 2,
        pauseInMS: 5000,
    };
    const USED_GRID = breakpoints.isLgAndAbove ? GRIDS_LARGE : GRIDS_SMALL;
    const [tilesAreCentered, setTilesAreCentered] = useState<boolean>(false);
    const [shouldAnimationRun, setShouldAnimationRun] = useState<boolean>(true);
    const { index, setIndex, resetIndex } = useIndexState(0);
    const hover = useHover();

    const [timeoutFirst, setTimeoutFirst] = useState<NodeJS.Timeout | undefined>(undefined);
    const [timeoutSecond, setTimeoutSecond] = useState<NodeJS.Timeout | undefined>(undefined);
    const [timeoutPause, setTimeoutPause] = useState<NodeJS.Timeout | undefined>(undefined);
    const [timeoutAnimation, setTimeoutAnimation] = useState<NodeJS.Timeout | undefined>(undefined);

    const clearTimeouts = (): void => {
        if (timeoutFirst) {
            clearTimeout(timeoutFirst);
            setTimeoutFirst(undefined);
        }

        if (timeoutSecond) {
            clearTimeout(timeoutSecond);
            setTimeoutSecond(undefined);
        }

        if (timeoutPause) {
            clearTimeout(timeoutPause);
            setTimeoutPause(undefined);
        }

        if (timeoutAnimation) {
            clearTimeout(timeoutAnimation);
            setTimeoutAnimation(undefined);
        }
    };

    const resetAnimation = () => {
        setShouldAnimationRun(false);
        clearTimeouts();
        if (timeoutAnimation) clearTimeout(timeoutAnimation);
        setTimeoutAnimation(
            setTimeout(() => {
                setShouldAnimationRun(true);
                resetIndex();
                setTilesAreCentered(false);
            }, 500),
        );
    };

    useEffect(() => {
        if (window) {
            window.addEventListener("resize", resetAnimation);
        }

        return () => {
            setIsMounted(false);
            clearTimeouts();
            window.removeEventListener("resize", resetIndex);
        };
    }, []);

    useEffect(() => {
        if (isMounted && shouldAnimationRun) {
            if (hover.isHovered && timeoutFirst) clearTimeout(timeoutFirst);

            if (!tilesAreCentered && !hover.isHovered) {
                if (timeoutFirst) clearTimeout(timeoutFirst);
                setTimeoutFirst(setTimeout(() => setTilesAreCentered(true), CONFIG.pauseInMS));
                if (timeoutPause) clearTimeout(timeoutPause);
                setTimeoutPause(
                    setTimeout(() => {
                        setShuffledTiles(shuffleArray(tiles) as IAnimatedGridTile[]);
                        setIndex(index + 1);
                    }, CONFIG.pauseInMS + 700),
                );
            } else {
                if (timeoutSecond) clearTimeout(timeoutSecond);
                setTimeoutSecond(
                    setTimeout(() => {
                        setTilesAreCentered(false);
                    }, 800),
                );
            }
        }
    }, [tilesAreCentered, hover.isHovered, shouldAnimationRun]);

    return (
        <div
            className="relative"
            style={{
                width: `${TileSize()}rem`,
                height: `${TileSize() * CONFIG.ratio}rem`,
            }}
            onMouseEnter={hover.onMouseEnter}
            onMouseLeave={hover.onMouseLeave}
        >
            {USED_GRID[index % USED_GRID.length].map((v, i) => (
                <AnimatedGridTile
                    key={`AnimatedGridTile_${v[0]}_${v[1]}_${v[2]}`}
                    size={v[0]}
                    offset={{ x: v[1], y: v[2] }}
                    ratio={CONFIG.ratio}
                    gridSize={CONFIG.gridSizeInRem}
                    centered={tilesAreCentered}
                    imgUrl={shuffledTiles[i]?.imgUrl || ""}
                    networkName={shuffledTiles[i]?.href}
                    href={shuffledTiles[i]?.href}
                />
            ))}
        </div>
    );
};

export default AnimatedGrid;
