import { DotsHorizontalIcon, EyeIcon, HeartIcon } from "@heroicons/react/solid";
import { IYoutubeInfosItem } from "@models/youtube/youtube";
import Link from "next/link";
import React, { FC } from "react";

export interface ICard {
    id?: string;
    imgUrl?: string;
    title: string;
    networkName: string;
    networkImgUrl: string;
    onClick?: () => void;
    type?: any;
    href?: string;
    imageFullyLoadedCallback?: () => void;
    viewCount?: string;
    likeCount?: string;
    youtubeItem?: IYoutubeInfosItem | undefined;
    onClickThumbnail?: () => void;
}

const Card: FC<ICard> = ({
    imgUrl,
    title,
    networkName,
    networkImgUrl,
    onClick,
    href,

    viewCount,
    likeCount,
    onClickThumbnail,
}: ICard) => {
    return (
        <div className="w-970 relative " onClick={onClick}>
            <div className="absolute top-5 -left-2 z-10 text-sm">
                {viewCount && (
                    <div className="bg-rc-pink py-2 px-6 text-white shadow-lg">
                        <div className="flex flex-row">
                            <EyeIcon className="mr-2 h-5 w-5 self-center" />
                            {viewCount}
                        </div>
                    </div>
                )}
                {likeCount && (
                    <div className="bg-rc-pink py-2 px-6 text-white shadow-lg">
                        <div className="flex flex-row">
                            <HeartIcon className="mr-2 h-5 w-5 self-center" />
                            {likeCount}
                        </div>
                    </div>
                )}
            </div>
            <div className="relative flex h-full items-center justify-center overflow-hidden rounded-lg">
                {href && (
                    <>
                        {onClickThumbnail ? (
                            <div className="h-full w-full" onClick={onClickThumbnail}>
                                <img
                                    src={imgUrl}
                                    alt=""
                                    className="w-full cursor-pointer bg-cover bg-center object-cover transition-all duration-500 ease-in-out hover:scale-110"
                                />
                            </div>
                        ) : (
                            <Link href={href || ""}>
                                <a className="w-full">
                                    <img
                                        src={imgUrl}
                                        alt=""
                                        className="w-full cursor-pointer bg-cover bg-center object-cover transition-all duration-500 ease-in-out hover:scale-110"
                                    />
                                </a>
                            </Link>
                        )}
                    </>
                )}
                {href === undefined && (
                    <a className="w-full">
                        <img
                            src={imgUrl}
                            alt=""
                            className="w-full cursor-pointer bg-cover bg-center object-cover transition-all duration-500 ease-in-out hover:scale-110"
                        />
                    </a>
                )}
            </div>

            <div className="flex flex-row pt-3">
                <img src={networkImgUrl} alt={networkName} className="h-6 w-6 rounded-full object-cover md:h-10 md:w-10" />
                <div className="flex w-full flex-col px-4 lg:flex-row">
                    <div className="w-full ">
                        {href !== undefined && (
                            <Link href={href || ""}>
                                <a className="truncate-2line text-xs font-bold text-black dark:text-white md:text-base ">{title}</a>
                            </Link>
                        )}
                        {href === undefined && (
                            <span className="truncate-2line text-xs font-bold text-black dark:text-white md:text-base ">{title}</span>
                        )}
                        <div className="flex flex-row justify-between pt-1">
                            <span className="cursor-default text-xs text-black dark:text-white ">via {networkName}</span>
                            <DotsHorizontalIcon onClick={onClickThumbnail} className="h-6 w-6 cursor-pointer self-end text-black dark:text-white" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;
