import { EXAMPLES } from "__mocks__/grid-cards-mock";
import { landingPageAnimatedGrid } from "__mocks__/landing-page-animated-grid";
import AnimatedGrid from "@components/animated-grid/animated-grid";
import EntryBenefits from "@components/entry-elements/entry-benefits/entry-benefits";
import EntryBenefitsCard from "@components/entry-elements/entry-benefits/entry-benefits-card";
import EntryBox from "@components/entry-elements/entry-box";
import EntryHero from "@components/entry-elements/entry-hero";
import EntrySignup from "@components/entry-elements/entry-signup";
import EntryHeroMobile from "@components/entry-elements/mobile/entry-hero-mobile";
import Card from "@components/grid/card";
import useBreakpoints from "@helper/custom-hooks/use-breakpoints";
import useSignUp from "@helper/custom-hooks/use-sign-up";
import useUnAuthOnly from "@helper/custom-hooks/use-un-auth-only";
import { normalLanguageHelper } from "@helper/static-language-helper";
import { NextPage } from "next";
import React from "react";
import { useTranslation } from "react-i18next";
import Masonry from "react-masonry-css";

export const getStaticProps = async ({ locale }) => {
    return normalLanguageHelper({ locale });
};

const IndexPage: NextPage = () => {
    useUnAuthOnly();
    const { t } = useTranslation();
    const breakpoints = useBreakpoints();
    const {
        user,
        setUser,
        openClose: { handleOpen },
    } = useSignUp();
    // const [allInterests, setAllInterests] = useState<IInterests[]>([])

    // useEffect(() => {
    //   if (signal)
    //     interests.getAll(signal).then(setAllInterests).catch(console.error)
    // }, [signal])

    return (
        <div className="flex flex-col justify-center">
            {breakpoints.isLgAndAbove ? (
                <EntryHero
                    emailInput={{
                        value: user?.email,
                        onChange: (e) => setUser({ ...user, email: e.target.value }),
                    }}
                    bgImgUrl="/assets/benefits/hero.png"
                    highlightText="	All Networks. <br />
				One Place."
                    highlightSubtext={t("lander.intro.preheader")}
                    text={t("lander.intro.text")}
                    handleOpen={handleOpen}
                />
            ) : (
                <EntryHeroMobile
                    emailInput={{
                        value: user?.email,
                        onChange: (e) => setUser({ ...user, email: e.target.value }),
                    }}
                    bgImgUrl="/assets/benefits/mobile-hero.png"
                    highlightText="	All Networks. <br />
				One Place."
                    highlightSubtext={t("lander.intro.preheader")}
                    text={t("lander.intro.text")}
                />
            )}
            <EntryBenefits title={t("lander.our_benefits")}>
                <EntryBenefitsCard
                    title="All in One"
                    imgUrl="/assets/slide-1.webp"
                    text={`${t("benefits.first_box.text1")}<br/>${t("benefits.first_box.text2")}`}
                />
                <EntryBenefitsCard title="Trust Engine" imgUrl="/assets/slide-2.webp" text={t("benefits.second_box.text1")} />
                <EntryBenefitsCard title="Influencer Toolbox" imgUrl="/assets/slide-3.webp" text={t("benefits.third_box.text1")} />
                <EntryBenefitsCard
                    title="Innovative
					Filters"
                    imgUrl="/assets/slide-4.webp"
                    text={t("benefits.fourth_box.text1")}
                />
            </EntryBenefits>
            <div className="bg-rc-dark text-center ">
                <div className="container mx-auto border-b border-rc-pink py-14">
                    <span className="text-gradient font-headline text-5xl font-bold lg:text-6xl">{t("lander.how_it_works.title")}</span>
                </div>
            </div>
            <EntryBox title="All in One." borderColor="pink">
                <div className="ml-10 mb-10 flex w-full flex-col items-center space-y-10 lg:my-0 lg:ml-0">
                    <div className="space-y-4 text-center lg:w-2/3">
                        <p className="text-white">{t("lander.how_it_works.text")}</p>
                    </div>

                    <div className="relative flex h-full lg:pl-24">
                        <img src="/assets/entry-phone.png" alt="" className="animate-hover left-22 absolute top-0 h-80 sm:h-96 md:h-480 lg:h-560" />
                        <img src="/assets/entry-circle.png" alt="" className="h-80 sm:h-96 md:h-480 lg:h-560" />
                    </div>
                </div>
            </EntryBox>
            {/* <EntryBox
        title="Your Interests"
        borderColor="blue"
        bgTailwind="bg-rc-black"
      >
        <div className="flex h-full w-full justify-center">
          <div className="my-10 ml-10 grid grid-cols-2 place-items-center gap-8 self-center md:grid-cols-3 lg:ml-0 lg:px-10 xl:grid-cols-4">
            {allInterests.slice(0, 20).map((v) => (
              <InterestTile
                title={v?.name}
                key={`InterestTitle_${v.name}`}
                imgUrl={v?.picture}
              />
            ))}
          </div>
        </div>
      </EntryBox> */}
            <EntryBox title="Your Accounts" borderColor="pink" bgImgUrl="/assets/entry-account-bg.png">
                <div className="flex h-full w-full justify-center">
                    <div className="self-center">
                        <AnimatedGrid tiles={landingPageAnimatedGrid} />
                    </div>
                </div>
            </EntryBox>
            <EntryBox title="Your Better Timeline" borderColor="blue" bgTailwind="bg-rc-black">
                <div className="my-10 ml-10 flex h-full w-full justify-center lg:ml-0 lg:px-10">
                    <div className="self-center">
                        {/* {getGridCardsMock(CardCount()).map((v, i) => ( */}
                        <Masonry breakpointCols={{ default: 4, 800: 2 }} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
                            {EXAMPLES.map((v, index) => (
                                <Card
                                    imgUrl={v.imgUrl}
                                    title={v.title}
                                    networkImgUrl={v.networkImgUrl}
                                    networkName={v.networkName}
                                    type={v.type}
                                    key={`Card_${v.title + index}`}
                                />
                            ))}
                        </Masonry>
                    </div>
                </div>
            </EntryBox>
            <EntrySignup
                title={t("generic.join_now")}
                bgImgUrl="/assets/entry-signup-bg.webp"
                emailInput={{
                    value: user?.email,
                    onChange: (e) => setUser({ ...user, email: e.target.value }),
                }}
                handleOpen={handleOpen}
            />
        </div>
    );
};

export default IndexPage;
