import { ICard } from "@components/grid/card";
import { getRandomInteger } from "@helper/get-random-integer";
import { getRange } from "@helper/get-range";

export const EXAMPLES: ICard[] = [
    {
        imgUrl: "https://i.scdn.co/image/ab67616d0000b2732a038d3bf875d23e4aeaa84e",
        title: "Happier Than Ever",
        networkName: "Spotify",
        networkImgUrl: "/media-icons/spotify.png",
        type: "music",
        href: "/audio/album/0JGOiO34nwfUdDrD612dOp",
    },
    {
        imgUrl: "https://occ-0-1008-2568.1.nflxso.net/dnm/api/v6/evlCitJPPCVCry0BZlEFb5-QjKc/AAAABf1zxBOcoK5bx2EsRHUZ-VDrHb_MnqBIuiI0fl1FEzn96dJtzXerxPkB7nRROZprLbtzGe00enzt168lHvKeY5rw-ajt.jpg?r=fd9",
        title: "The Office (U.S.)",
        networkName: "Netflix",
        networkImgUrl: "/media-icons/netflix-icon-color.png",
        type: "tvmovie",
        href: "/video/70136120",
    },
    {
        imgUrl: "https://occ-0-4039-2774.1.nflxso.net/dnm/api/v6/evlCitJPPCVCry0BZlEFb5-QjKc/AAAABTtHq45Zx--24KrrazjbLLb0tuzEvxjJoBm6Zr5M2nV5ZXuYa0HrND6eJViXccMDh3SLnGXVFxH7IOn7SVocnwGesrovGNrzvWNUszR83vQTneF8uTCmxVIM7XA.jpg?r=a90",
        title: "Squid Game",
        networkName: "Netflix",
        networkImgUrl: "/media-icons/netflix-icon-color.png",
        type: "tvmovie",
        href: "/video/81040344",
    },
    {
        imgUrl: "https://i.scdn.co/image/ab67616d0000b273be82673b5f79d9658ec0a9fd",
        title: "MONTERO",
        networkName: "Spotify",
        networkImgUrl: "/media-icons/spotify.png",
        type: "music",
        href: "/audio/album/6pOiDiuDQqrmo5DbG0ZubR",
    },
    {
        imgUrl: "https://i.scdn.co/image/ab67616d0000b273513d821e89b19367f6eb9e62",
        title: "Bad Habits",
        networkName: "Spotify",
        networkImgUrl: "/media-icons/spotify.png",
        type: "music",
        href: "/audio/album/01NhUvviMytvV12pmJiDZH",
    },
    {
        imgUrl: "https://occ-0-768-769.1.nflxso.net/dnm/api/v6/XsrytRUxks8BtTRf9HNlZkW2tvY/AAAABUKAEONB8hWU_0gKOBpijE002syh_bOY2z_lUud_F1xVv-jt5FlVdo1qQzjC2UWlFR0IX4Bdgfle8-w_z0Jo8iQf96CA5Tt1QDJU9cSZVHSGDTrCLp3FxzZ7d1f8PA.jpg?r=6a2",
        title: "Formula 1: Drive to Survive",
        networkName: "Netflix",
        networkImgUrl: "/media-icons/netflix-icon-color.png",
        type: "tvmovie",
        href: "/video/80204890",
    },
    {
        imgUrl: "https://occ-0-4039-2774.1.nflxso.net/dnm/api/v6/evlCitJPPCVCry0BZlEFb5-QjKc/AAAABW1JHnUkrySi-5h97gS8XEOUbmShh5aIZYIqMwOp1kCkPPjvyh54fSh8f9NdRTXnbzU1piYkEdR-W8yeN_GfGST8xpPVvjBlx756TxA5D6nJ2UiC94UYn9psP5E.jpg?r=fba",
        title: "Maid",
        networkName: "Netflix",
        networkImgUrl: "/media-icons/netflix-icon-color.png",
        type: "tvmovie",
        href: "/video/81166770",
    },
    {
        imgUrl: "https://i.scdn.co/image/ab67616d0000b273eda22390e79ef5e8f070b124",
        title: "My Universe",
        networkName: "Spotify",
        networkImgUrl: "/media-icons/spotify.png",
        type: "music",
        href: "/audio/album/39McjovZ3M6n5SFtNmWTdp",
    },
];

export const getGridCardsMock = (count: number): ICard[] => {
    const range = getRange(0, count);

    return range.map(() => EXAMPLES[getRandomInteger(0, EXAMPLES.length)]);
};
